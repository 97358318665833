
  import { defineComponent, ref } from 'vue'
  import MyProjectSearch from '@/components/Select/MyprojectSearch.vue'
  // import MyPlanStep from '@/components/Select/MyPlanStep.vue'
  import MyPlanStatus from '@/components/Select/MyPlanStatus.vue'
  import MyAdminSelect from '@/components/Select/MyAdminSelect.vue'
  import MyDatePicker from '@/components/MyDatePicker/index.vue'
  import MyUploadBtn from '../../../components/MyUpload/index.vue'
  export default defineComponent({
    components: {
      MyProjectSearch,
      // MyPlanStep,
      MyPlanStatus,
      MyAdminSelect,
      MyDatePicker,
      MyUploadBtn
    },
    props: {
      search_data: Object,
      type: String,
    },
    emits: ['onchange'],
    setup(props, ctx) {
      const searchData = ref(props.search_data)
      const onSubmit = () => {
        ctx.emit('onchange', searchData.value)
        console.log(searchData, '提交')
      }
      return {
        searchData,
        onSubmit,
      }
    },
  })
