
import { notification } from 'ant-design-vue';
  import { PlusOutlined } from '@ant-design/icons-vue'
  import { defineComponent, onMounted, ref } from 'vue'
  import { API, requests } from '@/api/apis'
  export default defineComponent({
    components: {
      PlusOutlined,
    },
    emits: ['onchange'],
    props: {
      indexs: Number,
      idxs: Number,
    },
    setup(props, ctx) {
      const ActionUrl = ref('')
      const dataObj = ref()
      const progressNumber = ref(0)
      const beforeUploadFun = (file: any) => {
        const isLt2M = file.size / 1024 / 1024 < 3;
        console.log(isLt2M,file.size,'isLt2M')
        if (!isLt2M) {
          notification['error']({
            message: '提示',
            description: '请上传1M-3M以内的成果素材！'});
        }
        const index = file.name.lastIndexOf('.')
        const filename =
          new Date().getTime() +
          '.' +
          file.name.substring(index + 1, file.name.length)
        dataObj.value.key = 'uploads/' + filename
        console.log(filename, dataObj.value, '名字')
      }
      const getUrl = () => {
        requests({
          url: API.Auth.AliyunSts,
          data: {},
        }).then((res) => {
          const data = res.data as any
          ActionUrl.value = data.host
          const dataInfo = {
            policy: data.policy,
            OSSAccessKeyId: data.accessid,
            success_action_status: '200',
            signature: data.signature,
          }
          dataObj.value = dataInfo
          console.log(dataObj.value, '立即')
        })
      }
      const uploadSchedule = (v: any) => {
        console.log(v, 'skks')
        if (v.event) {
          progressNumber.value = v.event.percent
          if (v.event.percent == 100) {
            ctx.emit('onchange', {
              url: ActionUrl.value + '/' + dataObj.value.key,
              index: props.indexs,
              idxs: props.idxs,
            })
            setTimeout(() => {
              progressNumber.value = 0
            }, 1000)
          }
        }
      }
      onMounted(() => {
        getUrl()
      })
      return {
        beforeUploadFun,
        ActionUrl,
        dataObj,
        uploadSchedule,
        progressNumber,
      }
    },
  })
