import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "headerSearch" }
const _hoisted_2 = { class: "bodyTable" }
const _hoisted_3 = { class: "tableTitle" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "tableOperating" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_search = _resolveComponent("page-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_my_plans_step = _resolveComponent("my-plans-step")!
  const _component_my_edit_button = _resolveComponent("my-edit-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_my_pagination = _resolveComponent("my-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_page_search, {
        search_data: _ctx.search.form,
        onOnchange: _ctx.search.onSubmit
      }, null, 8, ["search_data", "onOnchange"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.table.title), 1)
      ]),
      _createVNode(_component_a_table, _mergeProps({
        columns: _ctx.table.columns
      }, _ctx.table.props.table, {
        "data-source": _ctx.table.data.value,
        pagination: false,
        onChange: _ctx.table.onChange
      }), {
        status: _withCtx(({ record }) => [
          _createVNode(_component_a_tag, {
            color: _ctx.tagColor[record.status]
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.status), 1)
            ]),
            _: 2
          }, 1032, ["color"])
        ]),
        step: _withCtx(({ record }) => [
          _createVNode(_component_my_plans_step, {
            value: record.current_steps_label,
            "onUpdate:value": ($event: any) => ((record.current_steps_label) = $event)
          }, null, 8, ["value", "onUpdate:value"])
        ]),
        action: _withCtx(({ record }) => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_my_edit_button, {
              title: "发起",
              icon: "Eyes",
              onClick: ($event: any) => (_ctx.queryProjectPlans(record.id))
            }, null, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 16, ["columns", "data-source", "onChange"])
    ]),
    _createVNode(_component_my_pagination, {
      data: _ctx.pagination.data.value,
      onChange: _ctx.pagination.onChange
    }, null, 8, ["data", "onChange"])
  ], 64))
}